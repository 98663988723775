import React from 'react';
import { Store } from 'redux';
import {
  Ad,
  AppContent,
  ErrorBoundary,
  MultiLayerBreadcrumbs,
  NetworkLinkButtonList,
  OneHC,
  SecondarySectionTitle,
  SectionHeader,
  SectionWithTitle,
  baseUnit,
  ExpandableHC,
  VerticalCard,
  MEDIA_BREAKPOINTS,
  Span,
  PaginationButtonGroup,
} from 'mm-ui-components';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useSelector } from 'react-redux';
import { createImageWidths } from 'mm-ui-components/dist/src/base/partials/image/image.utils';
import { TypographySizes } from 'mm-ui-components/dist/src/typography/semanticTags/semanticTags.utils';
import { NavigationLayout } from '../../components/connectedComponents/NavigationLayout';
import { Theme } from '../../components/connectedComponents/Theme';
import { Page } from '../Page';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import { ErrorReporter } from '../../errorReporting/errorReporter.type';
import { useMeasureHydrateTime } from '../useMeasureHydrateTime';
import { MetaTags } from '../../components/connectedComponents/MetaTags';
import { HeadScripts } from '../../components/connectedComponents/HeadScripts';
import { AdditionalStyleSheet, getPageMetaText } from '../templates.utils';
import {
  getHreflangData,
  getMetadataDescription,
  getMetadataImage,
  getMetadataTitle,
  getPagination,
  getSectionArticles,
  getSectionSubtitle,
  getSectionTitleByKey,
  getShowMorePaginationTextByKey,
  getShowMorePaginationURLByKey,
} from '../../store/template/feedPage/feedPageLima/feedPageLima.selectors';
import { SECTION_NAMES, SLOT_NAMES } from '../templates.const';
import {
  getAdSlotId,
  getAdSlotOnSuccess,
  getCanonicalUrl,
  getPaletteColors,
  getPropertyEndpoint,
  getUseFormatMinutesHoursAgo,
} from '../../store/config/config.selectors';
import { getNetworkLinks } from '../../store/navigations/navigation/networkLinks/networkLinks.selector';
import {
  LoadMoreButton,
  useFetchNextPosts,
} from '../../components/connectedComponents/LoadMoreButton';
import { CardComponentDataProps } from '../../../../site-ui-components-library/src/base/partials/cards/cards.utils';
import { createBreadcrumbsWithoutMainCategory } from '../../store/template/postPage/utils/createMainCategory.utils';
import { createSIMetaImage } from '../../store/template/template.utils';
import { SI_KIDS } from '../../store/config/config.utils';

interface FeedPageLimaProps {
  store: Store<AppState, AppActions>;
  errorProvider: ErrorReporter;
}

export const FeedPageLimaComponent: React.FunctionComponent<
  FeedPageLimaProps
> = ({ store, errorProvider }) => {
  useMeasureHydrateTime();
  return (
    <Page store={store} errorProvider={errorProvider}>
      <ErrorBoundary>
        <FeedPageLimaContentConnected />
      </ErrorBoundary>
    </Page>
  );
};

const createStyle = (colors: any) => StyleSheet.create({
    sectionsWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridRowGap: `${baseUnit * 2}px`,
      marginTop: `${baseUnit * 1}px`,
      marginBottom: `${baseUnit * 0.5}px`,
      justifyItems: 'center',
      [MEDIA_BREAKPOINTS.medium]: {
        padding: '0 20px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: '0 20px',
      },
    },
    adContainer: {
      height: '140px',
      [MEDIA_BREAKPOINTS.large]: {
        marginBottom: `${baseUnit * 1.5}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginBottom: `${baseUnit * 1.5}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginBottom: `${baseUnit}px`,
      },
    },
    sectionWrapper: {
      display: 'flex',
      width: '100%',
    },
    titleSection: {
      flexDirection: 'column',
    },
    hcCard: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'unset',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'unset',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
    vcCard: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'unset',
      },
    },
    subtitleContainer: {
      marginTop: '10px',
      color: colors.grey,
    },
  });

const breadcrumbsStyle = StyleSheet.create({
  style: {
    display: 'flex',
    width: '100%',
  },
}) as AdditionalStyleSheet;

const buttonStyle = () => StyleSheet.create({
    style: {
      borderRadius: '4px',
      height: '52px',
    },
  }) as AdditionalStyleSheet;

const paddingStyle = StyleSheet.create({
  style: {
    paddingTop: '0px',
  },
}) as AdditionalStyleSheet;

const getArticlesInTemplate = (state: AppState) => {
  const firstSectionArticles = getSectionArticles(SECTION_NAMES.SECTION1)(state) || [];
  const expandableSectionArticles = getSectionArticles(SECTION_NAMES.EXPANDABLE_SECTION)(state) || [];
  return [...firstSectionArticles, ...expandableSectionArticles];
};

export const FeedPageLimaContentConnected: React.FunctionComponent = () => {
  const allArticles = useSelector(getArticlesInTemplate);
  const metaTitle = useSelector(getMetadataTitle);
  const metaDescription = useSelector(getMetadataDescription);
  const hreflangData = useSelector(getHreflangData) || [];
  const paginationData = useSelector(getPagination);
  const topSectionTitle = useSelector(getSectionTitleByKey(SECTION_NAMES.TOP_SECTION));
  const subtitle = useSelector(getSectionSubtitle(SECTION_NAMES.TOP_SECTION));
  const firstArticle = useSelector(getSectionArticles(SECTION_NAMES.SECTION1));
  const expandableSectionArticles = useSelector(getSectionArticles(SECTION_NAMES.EXPANDABLE_SECTION));
  const expandableSectionTitle = useSelector(getSectionTitleByKey(SECTION_NAMES.EXPANDABLE_SECTION));
  const topAdId = useSelector(getAdSlotId(SLOT_NAMES.TOP_AD));
  const topAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.TOP_AD));
  const belowTopSectionAdId = useSelector(getAdSlotId(SLOT_NAMES.BELOW_TOP_SECTION));
  const belowTopSectionAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.BELOW_TOP_SECTION));
  const oneOnOneAdId = useSelector(getAdSlotId(SLOT_NAMES.ONE_ON_ONE));
  const oneOnOneAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.ONE_ON_ONE));
  const propertyEndpoint = useSelector(getPropertyEndpoint);
  const useFormatMinutesHoursAgo = useSelector(getUseFormatMinutesHoursAgo);
  const canonicalURL = useSelector(getCanonicalUrl);
  let pageImage = useSelector(getMetadataImage);
  pageImage = createSIMetaImage(canonicalURL, pageImage);
  const networkLinks = useSelector(getNetworkLinks);
  const paddingFactors = { small: 0, medium: 0, large: 0 };
  const colors = useSelector(getPaletteColors);
  const vcImageWidths = createImageWidths(335, 335, 335);
  const styles = createStyle(colors);
  const shouldShowAds = propertyEndpoint !== SI_KIDS;

  const { prevButtonText, prevButtonURL, nextButtonText, nextButtonURL, currentPage } = paginationData;

  return (
    <React.Fragment>
      <MetaTags
        {...getPageMetaText(metaTitle, metaDescription, currentPage)}
        articles={allArticles}
        pageImage={pageImage}
        alternateUrls={hreflangData}
        breadcrumbs={createBreadcrumbsWithoutMainCategory(canonicalURL)}
      />
      <HeadScripts />
      <Theme>
        <AppContent>
          <NavigationLayout mainWrapperConfigThemeKey="mainContent" noMarginTop>
            {shouldShowAds && <Ad id={topAdId} onSuccess={topAdOnSuccess} setMinHeight />}
            <div className={css(styles.sectionsWrapper)}>
              <SectionWithTitle>
                <MultiLayerBreadcrumbs
                  canonicalURL={canonicalURL}
                  additionalStyle={breadcrumbsStyle}
                />
              </SectionWithTitle>
              {(topSectionTitle || subtitle) && (
                <SectionWithTitle>
                  <div className={css(styles.sectionWrapper, styles.titleSection)}>
                    {topSectionTitle && (
                      <SecondarySectionTitle>
                        {topSectionTitle}
                      </SecondarySectionTitle>
                    )}
                    {subtitle && (
                      <Span
                        styles={[styles.subtitleContainer]}
                        fontSizeLarge={TypographySizes.BIG}
                        fontSizeMedium={TypographySizes.BIG}
                        fontSizeSmall={TypographySizes.BIG}
                      >
                        {subtitle}
                      </Span>
                    )}
                  </div>
                </SectionWithTitle>
              )}

              {firstArticle && (
              <SectionWithTitle>
                <div className={css(styles.vcCard)}>
                  <ErrorBoundary>
                    <VerticalCard
                      {...(firstArticle[0] as CardComponentDataProps)}
                      imageWidths={vcImageWidths}
                    />
                  </ErrorBoundary>
                </div>
                <div className={css(styles.hcCard)}>
                  <OneHC
                    cards={firstArticle}
                    useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
                    paddingFactors={paddingFactors}
                  />
                </div>
              </SectionWithTitle>
            )}
              {networkLinks?.links.length ? (
                <SectionWithTitle>
                  <SectionHeader
                    moreButtonSvg={null}
                    moreButtonText={null}
                    paddingFactors={paddingFactors}
                  >
                    <SecondarySectionTitle>
                      {networkLinks.title}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <NetworkLinkButtonList additionalStyle={paddingStyle} links={networkLinks.links} />
                </SectionWithTitle>
              ) : null}
              {shouldShowAds && <Ad id={belowTopSectionAdId} onSuccess={belowTopSectionAdOnSuccess} setMinHeight />}
              {expandableSectionTitle && expandableSectionArticles && (
                <SectionWithTitle>
                  <SectionHeader
                    moreButtonSvg={null}
                    moreButtonText={null}
                    paddingFactors={paddingFactors}
                  >
                    <SecondarySectionTitle>
                      {expandableSectionTitle}
                    </SecondarySectionTitle>
                  </SectionHeader>
                  <ExpandableHC
                    cards={expandableSectionArticles}
                    paddingFactors={paddingFactors}
                    showImageHeightByAspectRatio
                    useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
                  />
                </SectionWithTitle>
              )}
              {Object.keys(paginationData).length === 0
              ? <LoadMoreFeedPostsButtonConnected />
              : <PaginationButtonGroup prevText={prevButtonText} prevURL={prevButtonURL} nextText={nextButtonText} nextURL={nextButtonURL} />}
              {shouldShowAds && <Ad id={oneOnOneAdId} onSuccess={oneOnOneAdOnSuccess} setMinHeight />}
            </div>
          </NavigationLayout>
        </AppContent>
      </Theme>
    </React.Fragment>
  );
};

const LoadMoreFeedPostsButtonConnected: React.FunctionComponent = () => {
  const sectionKey = SECTION_NAMES.EXPANDABLE_SECTION;
  const shouldDisplay = !!useSelector(getShowMorePaginationURLByKey(sectionKey));
  const text = useSelector(getShowMorePaginationTextByKey(sectionKey));
  const onClick = useFetchNextPosts(sectionKey, getShowMorePaginationURLByKey);
  return (
    <LoadMoreButton
      {...{ onClick, shouldDisplay, text }}
      additionalStyle={buttonStyle()}
    />
  );
};
