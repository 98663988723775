import { AppState, FeedPageLimaAppState } from '../../../initialStateRegistration';
import {
    FeedPageLimaArticles,
    FeedPageLimaTextSection,
    FeedPageLimaSectionWithSubTitle,
    FeedPageLimaExpandable,
} from './feedPageLima.initialState';

export const getFeedPage = (state: AppState) => (state as FeedPageLimaAppState).template;

export const getSectionTitleByKey = (key: FeedPageLimaTextSection) => (state: AppState) => getFeedPage(state)[key].sectionTitle;

export const getSectionSubtitle = (key: FeedPageLimaSectionWithSubTitle) => (state: AppState) => getFeedPage(state)[key].sectionSubtitle;

export const getSectionArticles = (key: FeedPageLimaArticles) => (state: AppState) => getFeedPage(state)[key].articles;

export const getShowMorePaginationTextByKey = (key: FeedPageLimaExpandable) => (state: AppState) => getFeedPage(state)[key].showMorePaginationText;

export const getShowMorePaginationURLByKey = (key: FeedPageLimaExpandable) => (state: AppState) => getFeedPage(state)[key].showMorePaginationURL;

export const getMetadataDescription = (state: AppState) => getFeedPage(state).metadataDescription;

export const getMetadataTitle = (state: AppState) => getFeedPage(state).metadataTitle;

export const getMetadataImage = (state: AppState) => getFeedPage(state).metadataImage;

export const getHreflangData = (state: AppState) => getFeedPage(state).hreflangData;

export const getPagination = (state: AppState) => getFeedPage(state).pagination;
