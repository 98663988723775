import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { StyleSheet } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
export const TypographySizes = {
    GIGANTIC: 'gigantic',
    HUGE: 'huge',
    LARGE: 'large',
    BIGGER: 'bigger',
    BIG: 'big',
    NORMAL: 'normal',
    TINY: 'tiny',
};
export const semanticCSS = (...styles) => styles;
export const getLineHeightBySize = (fontSizesStyle, fontSize, screenSize) => {
    return parseFloat(fontSizesStyle[fontSize].breakpoints[screenSize].lineHeight);
};
export const getStyleBySize = (fontSizesStyle, fontSizes) => {
    const { fontSizeLarge, fontSizeMedium, fontSizeSmall } = fontSizes;
    return StyleSheet.create({
        tagStyle: {
            [MEDIA_BREAKPOINTS.large]: {
                ...fontSizesStyle[fontSizeLarge].style,
                ...fontSizesStyle[fontSizeLarge].breakpoints.large,
            },
            [MEDIA_BREAKPOINTS.medium]: {
                ...fontSizesStyle[fontSizeMedium].style,
                ...fontSizesStyle[fontSizeMedium].breakpoints.medium,
            },
            [MEDIA_BREAKPOINTS.small]: {
                ...fontSizesStyle[fontSizeSmall].style,
                ...fontSizesStyle[fontSizeSmall].breakpoints.small,
            },
            margin: 0,
        },
    }).tagStyle;
};
export const getFontSizesBySemanticTag = (semanticTag) => {
    switch (semanticTag) {
        case 'h1':
            return {
                fontSizeLarge: TYPOGRAPHY_SIZE.GIGANTIC,
                fontSizeMedium: TYPOGRAPHY_SIZE.GIGANTIC,
                fontSizeSmall: TYPOGRAPHY_SIZE.GIGANTIC,
            };
        case 'h2':
            return {
                fontSizeLarge: TYPOGRAPHY_SIZE.HUGE,
                fontSizeMedium: TYPOGRAPHY_SIZE.HUGE,
                fontSizeSmall: TYPOGRAPHY_SIZE.HUGE,
            };
        case 'h3':
            return {
                fontSizeLarge: TYPOGRAPHY_SIZE.LARGE,
                fontSizeMedium: TYPOGRAPHY_SIZE.LARGE,
                fontSizeSmall: TYPOGRAPHY_SIZE.LARGE,
            };
        case 'h4':
            return {
                fontSizeLarge: TYPOGRAPHY_SIZE.BIGGER,
                fontSizeMedium: TYPOGRAPHY_SIZE.BIGGER,
                fontSizeSmall: TYPOGRAPHY_SIZE.BIGGER,
            };
        case 'h5':
            return {
                fontSizeLarge: TYPOGRAPHY_SIZE.BIG,
                fontSizeMedium: TYPOGRAPHY_SIZE.BIG,
                fontSizeSmall: TYPOGRAPHY_SIZE.BIG,
            };
        case 'h6':
            return {
                fontSizeLarge: TYPOGRAPHY_SIZE.TINY,
                fontSizeMedium: TYPOGRAPHY_SIZE.TINY,
                fontSizeSmall: TYPOGRAPHY_SIZE.TINY,
            };
        case 'span':
            return {
                fontSizeLarge: TYPOGRAPHY_SIZE.NORMAL,
                fontSizeMedium: TYPOGRAPHY_SIZE.NORMAL,
                fontSizeSmall: TYPOGRAPHY_SIZE.NORMAL,
            };
        case 'p':
        default:
            return {
                fontSizeLarge: TYPOGRAPHY_SIZE.NORMAL,
                fontSizeMedium: TYPOGRAPHY_SIZE.NORMAL,
                fontSizeSmall: TYPOGRAPHY_SIZE.NORMAL,
            };
    }
};
export const sanitizeAttributes = (attributes) => {
    const notAllowedAttributes = ['baseUnit', 'fontSizesStyles', 'isMainCategoryEnabled'];
    return Object.keys(attributes).reduce((newAttributes, attr) => {
        return {
            ...newAttributes,
            ...(notAllowedAttributes.includes(attr) ? null : { [attr]: attributes[attr] }),
        };
    }, {});
};
